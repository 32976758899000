import { EmbeddedLayout, LbLogoLoading, useModules, usePermissions } from "@lb/frontend";
import {
  dashboardClientUrls,
  departmentClientUrls,
  maintenanceModeClientUrl,
  settingsClientUrls,
  usersClientUrls,
  vendorsClientUrls,
} from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Maintenance from "./pages/Maintenance";

const CLMSignatoryMatrix = lazy(() =>
  import("./pages/Settings/Modules/CLM/[OBSOLETE] SignatoryMatrix")
);
const CLMReviewerMatrix = lazy(() =>
  import("./pages/Settings/Modules/CLM/[OBSOLETE] ReviewerMatrix")
);

const Matrix = lazy(() => import("./pages/Vendors/Matrix"));
const MatrixCreateEdit = lazy(() => import("./pages/Vendors/Matrix/CreateEdit"));

const IAMSettings = lazy(() => import("./pages/Settings/Modules/IAM"));
const CLMSettings = lazy(() => import("./pages/Settings/Modules/CLM"));
const DRMSettings = lazy(() => import("./pages/Settings/Modules/DRM"));

const OrganizationSettings = lazy(() => import("./pages/Settings/Organization/Details"));
const Profile = lazy(() => import("./pages/Settings/Profile"));
const Preferences = lazy(() => import("./pages/Settings/Profile/Preferences"));
const Security = lazy(() => import("./pages/Settings/Profile/Security"));
const Notifications = lazy(() => import("./pages/Settings/Profile/Notifications"));
const VendorSummary = lazy(() => import("./pages/Vendors/Summary"));
const SettingsLayout = lazy(() => import("./pages/Settings"));
const BulkVendorsSummary = lazy(() => import("./pages/Vendors/Bulk/Summary"));
const AddBulkVendors = lazy(() => import("./pages/Vendors/Bulk/Add"));
const BulkVendors = lazy(() => import("./pages/Vendors/Bulk"));

const InviteVendor = lazy(() =>
  usePermissions.getState().permissions?.iam?.vendors?.invite &&
  useModules.getState().modules?.iam?.vendors?.onboarding?.invite
    ? import("./pages/Vendors/Invite")
    : import("./pages/AccessDenied")
);

const RolesPermissions = lazy(() => import("./pages/Users/RolesPermissions"));
const Vendors = lazy(() => import("./pages/Vendors"));
const AddVendor = lazy(() => import("./pages/Vendors/Add"));
const EditVendor = lazy(() => import("./pages/Vendors/Edit"));
const Departments = lazy(() => import("./pages/Departments"));
const AddEditDepartment = lazy(() => import("./pages/Departments/AddEdit"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Users = lazy(() => import("./pages/Users"));
const AddInviteUsers = lazy(() => import("./pages/Users/AddEdit"));
const HomeDashboard = lazy(() => import("./pages/Dashboard/MyDashboard"));
const IAMDashboard = lazy(() => import("./pages/Dashboard/IAM"));
const CLMDashboard = lazy(() => import("./pages/Dashboard/CLM"));
const DRMDashboard = lazy(() => import("./pages/Dashboard/DRM"));
const Colors = lazy(() => import("./pages/Settings/Organization/Details/Colors"));
const AppLayout = lazy(() => import("./components/AppLayout"));

const router = createBrowserRouter([
  {
    path: maintenanceModeClientUrl.base,
    element: <Maintenance />,
  },
  {
    path: "/embedded",
    element: <EmbeddedLayout />,
    children: [
      {
        path: `${vendorsClientUrls.slug}/${vendorsClientUrls.edit.slug}`,
        element: <EditVendor />,
      },
      {
        path: "organization-colors",
        element: <Colors />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<LbLogoLoading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={dashboardClientUrls.base} replace />,
      },
      {
        path: dashboardClientUrls.slug,
        element: <Dashboard />,
        children: [
          {
            index: true,
            element: <HomeDashboard />,
          },
          {
            path: dashboardClientUrls.iam.slug,
            element: <IAMDashboard />,
          },
          {
            path: dashboardClientUrls.clm.slug,
            element: <CLMDashboard />,
          },
          {
            path: dashboardClientUrls.drm.slug,
            element: <DRMDashboard />,
          },
        ],
      },
      {
        path: settingsClientUrls.base,
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={settingsClientUrls.profile.slug} />,
          },
          {
            path: settingsClientUrls.profile.slug,
            children: [
              {
                index: true,
                element: <Navigate replace to={settingsClientUrls.profile.details.base} />,
              },
              {
                path: settingsClientUrls.profile.details.slug,
                element: <Profile />,
              },
              {
                path: settingsClientUrls.profile.preferences.slug,
                element: <Preferences />,
              },
              {
                path: settingsClientUrls.profile.notification.slug,
                element: <Notifications />,
              },
              {
                path: settingsClientUrls.profile.security.slug,
                element: <Security />,
              },
            ],
          },
          {
            path: settingsClientUrls.organization.slug,
            children: [
              {
                index: true,
                element: <OrganizationSettings />,
              },
            ],
          },
          {
            path: settingsClientUrls.modules.slug,
            children: [
              {
                index: true,
                element: <Navigate to={settingsClientUrls.modules.iam.base} />,
              },
              {
                path: settingsClientUrls.modules.iam.slug,
                element: <IAMSettings />,
              },
              {
                path: settingsClientUrls.modules.clm.slug,
                element: <CLMSettings />,
                children: [
                  {
                    index: true,
                    element: (
                      <Navigate replace to={settingsClientUrls.modules.clm.signatoryMatrix.base} />
                    ),
                  },
                  {
                    path: settingsClientUrls.modules.clm.signatoryMatrix.slug,
                    element: <CLMSignatoryMatrix />,
                  },
                  {
                    path: settingsClientUrls.modules.clm.reviewerMatrix.slug,
                    element: <CLMReviewerMatrix />,
                  },
                ],
              },
              {
                path: settingsClientUrls.modules.drm.slug,
                element: <DRMSettings />,
              },
            ],
          },
        ],
      },
      {
        path: usersClientUrls.slug,
        element: <Users />,
        children: [
          {
            path: usersClientUrls.invite.slug,
            element: <AddInviteUsers />,
          },
          {
            path: usersClientUrls.edit.slug,
            element: <AddInviteUsers />,
          },
        ],
      },
      {
        path: usersClientUrls.rolesPermissions.base,
        element: <RolesPermissions />,
      },
      {
        path: departmentClientUrls.slug,
        element: <Departments />,
        children: [
          {
            path: departmentClientUrls.add.slug,
            element: <AddEditDepartment />,
          },
          {
            path: departmentClientUrls.edit.slug,
            element: <AddEditDepartment />,
          },
        ],
      },
      {
        path: vendorsClientUrls.base,
        children: [
          {
            path: vendorsClientUrls.base,
            element: <Vendors />,
            children: [
              {
                path: vendorsClientUrls.invite.slug,
                element: <InviteVendor />,
              },
            ],
          },
          {
            path: vendorsClientUrls.add.slug,
            element: <AddVendor />,
          },
          {
            path: vendorsClientUrls.edit.slug,
            element: <EditVendor />,
          },
          {
            path: vendorsClientUrls.bulk.slug,
            children: [
              {
                path: vendorsClientUrls.bulk.base,
                element: <BulkVendors />,
                children: [
                  {
                    path: vendorsClientUrls.bulk.add.slug,
                    element: <AddBulkVendors />,
                  },
                ],
              },
              {
                path: vendorsClientUrls.bulk.summary.slug,
                element: <BulkVendorsSummary />,
              },
            ],
          },
          {
            path: vendorsClientUrls.summary.slug,
            element: <VendorSummary />,
          },
          {
            path: "matrix",
            element: <Navigate replace to={"/matrix/reviewer"} />,
          },
          {
            path: vendorsClientUrls.matrix.slug,
            children: [
              { index: true, element: <Matrix /> },
              {
                path: vendorsClientUrls.matrix.create.slug,
                element: <MatrixCreateEdit />,
              },
              {
                path: vendorsClientUrls.matrix.update.slug,
                element: <MatrixCreateEdit />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
